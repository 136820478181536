export const fileAdapter = (data) => {
  if (!data?.file) return;

  return {
    text: data.file.text,
    link: data.file.link,
    size: data.file.size,
    ext: data.file.ext,
    date: data.file.date,
  };
};
